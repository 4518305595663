<template>
  <div class="app-context expanded">
    <!-- <app-header
      :headerOption="headerOption"
      ref="appheader"
      style="top : 10px"
    ></app-header> -->
    <join-progress :progressInfo="progressInfo"></join-progress>
    <div class="context">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="agreement">
        <div class="a-action" @click="allCheckAgree()">
          <div class="check">
            <img src="@/assets/images/ck_activated.svg" v-if="agreeChecked" />
            <img src="@/assets/images/ck_unActivated.svg" v-else />
          </div>
          <h4>약관에 모두 동의합니다.</h4>
        </div>
        <div class="article">
          <div
            class="item"
            v-for="(agreeType, index) in agreement"
            :key="index"
            @click="readAgreement(index)"
          >
            <div class="i-slot">
              <div class="check">
                <img src="@/assets/images/ck_on.svg" v-if="agreeType.flag" />
                <img src="@/assets/images/ck_un.svg" v-else />
              </div>
              <h4>{{ agreeType.type }}</h4>
            </div>
            <div class="btn-wrap">
              <button type="button" v-if="!agreement[index].flag">
                내용보기
              </button>
              <button type="button" v-else class="default">동의취소</button>
            </div>
          </div>
        </div>
        <div
          class="btn-wrap"
          :class="{ active: agreement[0].flag && agreement[1].flag }"
        >
          <button type="button" @click="identityCheck()">본인인증</button>
        </div>
      </div>
    </div>
    <!-- <app-footer></app-footer> -->
    <agreement-popup
      @agree-checked="checkAgreement($event)"
      @marketting-checked="getMarkettingResult"
      v-if="checkAgreementPopup"
      :popupSetAgree="popupSetAgree"
    />
    <auth-popup
      v-if="checkAuthPopup"
      :popupSet="popupAuthSet"
      @identify-result="receiveIdentifyResult"
    ></auth-popup>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import JoinProgress from "@/components/join/Progress";
import Directive from "@/components/join/Directive";
import AgreementPopup from "@/components/modal/AgreementPopup";
import DefaultPopup from "@/components/modal/DefaultPopup";
import AuthPopup from "@/components/modal/AuthPopup";
import AppFooter from "@/components/AppFooter";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    Directive,
    AgreementPopup,
    DefaultPopup,
    AuthPopup,
    JoinProgress,
  },
  data() {
    return {
      directiveInfo: {
        title: "약관동의",
        isTitleNewLine: false,
        content: "회원가입을 위해 이용약관에 동의해주세요.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      agreeChecked: false,
      agreement: [
        {
          flag: false,
          type: "[필수] 회원이용약관",
        },
        {
          flag: false,
          type: "[필수] 개인정보 수집 및 이용",
        },
        {
          flag: false,
          type: "[선택] 마케팅정보 수신동의",
        },
      ],
      headerOption: {
        navOrder: 1,
      },
      progressInfo: {
        activeNum: 0,
      },
      popupAuthSet: {},
      popupSet: {},
      popupSetAgree: {},
      markettingResult: null,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState("basic", [
      "checkPopup",
      "checkAgreementPopup",
      "checkAuthPopup",
    ]),
    ...mapGetters("join", ["GET_IDENTIFY"]),
  },
  methods: {
    ...mapMutations("basic", [
      "SET_POPUP",
      "SET_AGREEMENT_POPUP",
      "SET_AUTH_POPUP",
    ]),
    ...mapMutations("join", ["SET_AGREEMENT_VALUSE"]),
    ...mapActions("join", ["FETCH_ENC_DATA"]),
    receiveIdentifyResult(userInfo) {
      //console.log("userInfo", userInfo);
      if (userInfo.birthdate !== null) {
        this.storeAgreement();
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "본인인증 실패";
        this.popupSet.content = "본인인증이 완료되지 않았습니다. ";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    storeAgreement() {
      if (this.markettingResult !== null) {
        const agreement = {
          emailRcvAgrYn: this.markettingResult[0] ? "Y" : "N",
          smsRcvAgrYn: this.markettingResult[1] ? "Y" : "N",
          mktCollAgrYn:
            this.markettingResult[0] || this.markettingResult[1] ? "Y" : "N",
        };
        this.SET_AGREEMENT_VALUSE(agreement);
      }
      this.$router.push("/v/join/member").catch(()=>{}); // 화면 전환
    },
    allCheckAgree() {
      if (!this.agreeChecked) {
        const agreement = [true, true, true];
        this.markettingResult = agreement;
        this.agreeChecked = true;
      } else {
        this.agreeChecked = false;
        this.markettingResult = null;
      }
      this.allCheckOther(this.agreeChecked);
    },
    allCheckOther(isChecked) {
      //console.log("isChecked", isChecked);
      for (let j = 0; j < this.agreement.length; j++) {
        this.agreement[j].flag = isChecked;
      }
    },
    async identityCheck() {
      if (this.agreement[0].flag && this.agreement[1].flag) {
        try {
          await this.FETCH_ENC_DATA().then(() => {
            this.SET_AUTH_POPUP(true);
            this.popupAuthSet.isTopPriority = true;
            this.popupAuthSet.isTransparent = true;
          });
        } catch (error) {
          console.log("본인인증 시도 전 ENC-DATA를 받는 API Error: ", error);
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "동의";
        this.popupSet.content = "모든 항목에 동의해주시기 바랍니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    checkAgreement(order) {
      //console.log("order", order);
      this.agreement[order].flag = !this.agreement[order].flag;
      this.checkOther();
    },
    checkOther() {
      if (!this.agreement[0].flag || !this.agreement[1].flag) {
        this.agreeChecked = false;
        return false;
      } else if (
        this.agreement[0].flag &&
        this.agreement[1].flag &&
        this.agreement[2].flag
      ) {
        this.agreeChecked = true;
      }
    },
    readAgreement(type) {
      //console.log("type", type);
      if (this.agreement[type].flag) {
        if (type === 2) {
          this.markettingResult = null;
        }
        this.agreement[type].flag = !this.agreement[type].flag;
        this.agreeChecked = false;
      } else {
        this.SET_AGREEMENT_POPUP(true);
        this.popupSetAgree.agreeCheckNum = type;
        this.popupSetAgree.footerLess = true;
        this.popupSetAgree.isHuge = true;
      }
    },
    getMarkettingResult(result) {
      this.markettingResult = result;
      for (let i = 0; i < result.length; i++) {
        if (result[i]) {
          this.agreement[2].flag = true;
        }
      }
      this.checkOther();
    },
  },
  destroyed() {
    if (this.checkPopup !== undefined && this.checkPopup === true) {
      this.SET_POPUP(false);
    } else if (
      this.checkAgreementPopup !== undefined &&
      this.checkAgreementPopup === true
    ) {
      this.SET_AGREEMENT_POPUP(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.agreement {
  min-height: 400px !important;
  width: 720px;
  padding: 0;
  margin: 85px auto 30px auto;
  min-height: 630px;
  position: relative;
  &:after {
    content: "";
    background: #146f83;
    width: 100%;
    height: 3px;
    top: -20px;
    position: absolute;
  }
  .a-action {
    display: flex;
    padding: 0 0 20px 0;
    border: 0px solid #707070;
    border-width: 0px 0px 1px 0px;
    align-items: center;
    cursor: pointer;
    .check {
      margin: 3px 30px 0 0;
      img {
      }
    }
    h4 {
      font-family: "SUIT";
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }
  .article {
    margin: 20px 0;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin: 17.5px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      .i-slot {
        display: flex;
        .check {
          margin: 0 30px 0 0;
          display: flex;
          img {
            width: 150%;
            min-width: 27.38px;
          }
        }
        h4 {
          font-family: "SUIT";
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }
      .btn-wrap {
        margin: 0;
        height: 41px;
        width: 110px;
        background: transparent;
        button {
          position: relative;
          font-family: SUIT;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: center;
          color: #146f83;
          &.default {
            color: #000000;
            &:after {
              background: #000000;
            }
          }
          &:after {
            position: absolute;
            width: 55px;
            bottom: 8px;
            content: "";
            left: 50%;
            height: 1px;
            transform: translateX(-50%);
            background: #156f83;
          }
        }
      }
    }
  }
  .btn-wrap {
    margin: 45px 0 0 0;
    background: #bcbcbc;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background: #146f83;
    }
    button {
      background: transparent;
      border: 0;
      font-family: "SUIT";
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      width: 100%;
      height: inherit;
    }
  }
}
@media screen and (max-width: 768px) {
  .agreement {
    width: 400px !important;
  }
}
@media screen and (max-width: 425px) {
  .agreement {
    width: 300px !important;
    height: 400px !important;
    min-height: 400px !important;
  }
}
</style>
